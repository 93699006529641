import React from "react"
import { As, AspectRatio, Box, Container, Heading, Image, Show, Text } from "@chakra-ui/react"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import { useRoutes } from "@app/hooks/useRoutes"
import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"
import Link from "@app/components/Link"

type Props = GatsbyTypes.SanitySectionBanner & AnalyticProps

const Banner: React.FC<Props> = ({ title, link, image, innerRef, gradientStop1, gradientStop2, handleTrackingClick, tag }) => {
  const { urlResolver } = useRoutes()
  const { getGatsbyImage } = useImage()

  const customLink = urlResolver(link)
  const customImage = getGatsbyImage(image)

  const backgroundGradient =
    gradientStop1 && gradientStop2
      ? `linear-gradient(91.33deg, ${gradientStop1.hex} 0%, ${gradientStop2.hex} 99.16%)`
      : "background.gradientBanner"
  const finalStop = gradientStop2 ? gradientStop2.hex : "background.gradientStop"

  return title ? (
    <Box as="section" ref={innerRef}>
      <Container maxW="container.4xl" px={{ base: "4", md: "8", lg: "20" }} pb={{ base: "4", lg: "8" }}>
        <Box d={{ lg: "flex" }} alignItems={{ lg: "stretch" }} borderRadius="xl" overflow="hidden">
          <Box
            d={{ lg: "flex" }}
            flexDir={{ lg: "column" }}
            justifyContent={{ lg: "center" }}
            py={{ base: "8", lg: "10.5" }}
            px={{ base: "4", lg: "14" }}
            bg={backgroundGradient}
            color="background.white"
            textAlign={{ base: "center", lg: "left" }}
            w={{ lg: "63.83%" }}
          >
            <Heading as={tag as As} size="h3">
              {title}
            </Heading>
            {customLink && (
              <Text
                as={Link}
                to={customLink.url}
                external={customLink.external}
                onClick={handleTrackingClick}
                size="textLinkMedium"
                d="block"
                mt={{ base: "3", lg: "4" }}
                _hover={{ textDecor: "underline" }}
              >
                {customLink.title}
              </Text>
            )}
          </Box>

          {customImage && (
            <Show above="lg">
              <Box pos="relative" w="36.17%" overflow="hidden">
                <Box as="span" pos="absolute" top="-1px" left="-1px" w="72.5" h="42" zIndex="docked" color={finalStop}>
                  <Icon name="bannerVector" width="100%" height="100%" />
                </Box>

                <AspectRatio ratio={464 / 168} w="full" h="full" maxH="42">
                  <Image {...customImage} />
                </AspectRatio>
              </Box>
            </Show>
          )}
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(Banner))
